<template>
  <p
    v-if="usersListDataTable.length === 0"
    class="
      text-center text-xs
      md:text-sm
      font-montserrat font-semibold
      text-magenta
    "
  >
    No one connected
  </p>
  <div v-else>
    <table class="text-left w-full">
      <thead>
        <tr>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div
              class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7"
            >
              <span>Name</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div
              class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7"
            >
              <span>Email</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody :class="{ 'animate-pulse': data.isLoading }">
        <template v-if="!data.isLoading">
          <tr
            v-for="(u, index) in usersListDataTable"
            :key="index"
            :class="{
              'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
            }"
          >
            <td
              class="
                text-xs
                md:text-sm
                lg:text-base
                text-black-1
                dark:text-dark-textPrimary
                font-medium
                p-2.5
                md:p-5
                lg:py-3.5
                lg:px-7
              "
            >
              {{ u.fullName }}
            </td>
            <td
              class="
                text-xs
                md:text-sm
                lg:text-base
                text-black-1
                dark:text-dark-textPrimary
                font-medium
                p-2.5
                md:p-5
                lg:py-3.5
                lg:px-7
              "
            >
              {{ u.email }}
            </td>
          </tr>
        </template>

        <template v-if="data.isLoading">
          <tr
            v-for="n in 5"
            :key="n"
            :class="{
              'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
            }"
            class="h-14"
          >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </tbody>
    </table>
    <div
      v-if="usersListDataTable.length"
      class="flex border-t-2 border-grey-light pt-4 lg:pt-7"
    >
      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(1)"
      >
        First
      </button>

      <div v-for="page in totalPages" :key="`pagination-${page}`">
        <span
          v-if="isDisplayPageNumber(page)"
          :class="
            page === data.currentPage
              ? 'border-blue-light'
              : 'border-grey-light dark:border-dark-bgPrimary'
          "
          class="
            flex
            justify-center
            items-center
            w-8
            md:w-10
            h-8
            md:h-10
            text-grey-mid
            dark:text-dark-textPrimary
            rounded-full
            border-2
            shadow-sm
            cursor-pointer
            mr-2
          "
          @click="paginate(page)"
          >{{ page }}</span
        >
      </div>

      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(totalPages)"
      >
        Last
      </button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount } from "vue";

import { dynamicPaginate } from "@Helpers/datatable";

// import { filter, find, map } from "lodash";
import { size } from "lodash";

import { getTrackingInfo } from "@API/event/event-repository";
// import { empty } from "@/utils";

export default {
  name: "UsersTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  emits: ["onUpdate", "userOnline"],
  setup(props, { emit }) {
    const data = reactive({
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      userData: [],
    });

    const eventId = computed(() => props.eventDetail.id);
    const usersListDataTable = computed(() =>
      dynamicPaginate(data.userData, data.currentPage, data.perPage),
    );
    const totalPages = computed(() =>
      Math.ceil(data.userData.length / data.perPage),
    );

    onBeforeMount(() => {
      getUsers();
    });

    async function getUsers() {
      // Show loading
      toggleLoading();
      const resp = await getTrackingInfo(eventId.value);
      data.userData = resp.data;
      emit("userOnline", size(data.userData));
      toggleLoading();
    }

    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function toggleLoading() {
      data.isLoading = !data.isLoading;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    return {
      data,
      usersListDataTable,
      totalPages,
      getUsers,
      paginate,
      isDisplayPageNumber,
    };
  },
};
</script>
