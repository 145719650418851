<template>
  <div class="flex flex-col font-fs-elliot-pro">
    <div class="self-end mb-3" v-if="!empty(messageData)">
      <button @click="doExportCSV" class="uppercase bg-magenta border rounded-full p-3 focus:outline-none border-none text-white font-montserrat font-bold">Export CSV</button>
      <button @click="doClearAllChat" class="ml-2 uppercase bg-magenta border rounded-full p-3 focus:outline-none border-none text-white font-montserrat font-bold">Clear</button>
    </div>
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-1/6">
        <div class="relative">
          <div>
            <button
              id="options-menu"
              type="button"
              aria-haspopup="true"
              aria-expanded="true"
              class="
                inline-flex
                justify-between
                items-center
                w-full
                appearance-none
                bg-off-white-1
                dark:bg-dark-bgPrimary
                text-xs
                md:text-base
                lg:text-lg
                font-bold
                text-black-1
                dark:text-dark-textSecondary
                border border-grey-mid
                focus:border-none focus:outline-none focus:ring-2 focus:ring-magenta
                rounded
                px-4
                py-2
              "
              @click="toggleDropdown()"
            >
              Sort by Read Status
              <ArrowDownIcon class="-mr-1 ml-2 h-5 w-5" />
            </button>
          </div>

          <div ref="dropdown" class="origin-top-right absolute right-0 mt-2 w-full rounded shadow-lg bg-white dark:bg-dark-bgSecondary ring-1 ring-grey-mid transform hidden opacity-0">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a href="#" class="block text-xs md:text-base lg:text-lg text-black-1 dark:text-dark-textSecondary hover:bg-off-white-1 dark:hover:bg-dark-bgPrimary px-4 py-2" role="menuitem"
                >Sort by Time</a
              >
              <a href="#" class="block text-xs md:text-base lg:text-lg text-black-1 dark:text-dark-textSecondary hover:bg-off-white-1 dark:hover:bg-dark-bgPrimary px-4 py-2" role="menuitem"
                >Sort by Unread</a
              >
              <a href="#" class="block text-xs md:text-base lg:text-lg text-black-1 dark:text-dark-textSecondary hover:bg-off-white-1 dark:hover:bg-dark-bgPrimary px-4 py-2" role="menuitem"
                >Sort by Message</a
              >
            </div>
          </div>
        </div>

        <div class="h-60 lg:h-page-content overflow-y-auto bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid rounded py-2 px-1 mt-4">
          <user-conversation
            v-for="user in conversationData"
            :key="user.partner"
            :isSelected="userSelected?.partner === user.partner"
            v-show="!empty(messageData[user.id])"
            :user="user"
            :onUserSelected="onUserSelected"
            @onMessageChanged="onMessageChanged"
            :ref="
              el => {
                if (el) userInstance[user.id] = el;
              }
            "
          />
        </div>
      </div>

      <div class="w-full flex-1 lg:w-3/4 mt-5 lg:mt-0 lg:ml-10">
        <div class="relative h-full bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid rounded py-2 px-1" :class="[empty(userSelected) && 'pointer-events-none']">
          <div class="flex flex-col items-start h-page-content-small overflow-y-auto text-xs md:text-base lg:text-lg px-2 py-2">
            <div class="px-1 mb-5" v-for="(message, index) in messageData[userSelected?.id]" :key="index">
              <strong class="text-black-1 dark:text-dark-textSecondary" v-text="`${message.userName === userId ? 'You' : message.sender} (${$filters.formatTime(message.createdAt)}):`"> </strong>
              <span class="font-medium text-grey-dark ml-2" v-html="convertUrlToLink(decodeBase64(message.body))" />
            </div>
          </div>

          <input
            v-model="inputText"
            class="
              form-input
              absolute
              bottom-4
              left-1.5
              md:left-3.5
              lg::left-5
              w-96%
              h-10
              md:h-14
              text-xl text-grey-mid
              dark:text-dark-textPrimary
              font-semibold
              rounded-2rem
              border border-grey-mid
              placeholder-grey-mid
              focus:ring-magenta focus:border-magenta
              px-7
            "
            type="text"
            placeholder="Send a response"
            @keyup.enter="onCreateMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { TweenMax } from "gsap";

import ArrowDownIcon from "@Assets/images/ArrowDownIcon";

import { set, get, orderBy, map, find, isEmpty, forEach, some } from "lodash";

import UserConversation from "./UserConversation";
import { useQuery, useResult } from "@vue/apollo-composable";
import ConversationQuery from "@/graphql/query/Conversation";
import ConversationSubscription from "@/graphql/subscription/Conversation";
import { empty, convertUrlToLink, decodeBase64, encodeBase64 } from "@/utils";
import { formatDate, formatTime } from "@/helpers/date";
import { deleteUserList } from "@API/event/event-repository";

export default {
  name: "SupportTab",
  components: {
    ArrowDownIcon,
    UserConversation,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const { username, id } = store.getters.getUserInfo;
    const userInstance = ref({});
    const dropdown = ref(null);
    const userSelected = ref(null);
    let messageData = ref({});
    let inputText = ref();
    let userList = ref([]);
    let conversationData = [];

    const variables = {
      eventId: route.params.eventId,
    };
    const { result, subscribeToMore, refetch } = useQuery(ConversationQuery, variables);

    conversationData = useResult(result, null, data => orderBy(data.getConversations.items, ["createdAt"], ["desc"]));
    // watch(conversationData, value => {
    //   console.log(value[0]);
    //   onUserSelected(first(value));
    // });

    subscribeToMore(() => ({
      document: ConversationSubscription,
      variables,
      clientId: "supportClient",
      onError: error => {
        console.log("onError", { error });
      },
      updateQuery: (
        previousResult,
        {
          subscriptionData: {
            data: { onCreateConversation },
          },
        },
      ) => {
        if (!some(previousResult.getConversations.items, item => item.id === onCreateConversation.id)) {
          previousResult.getConversations.items.push(onCreateConversation);
          return previousResult;
        }
      },
    }));

    const onCreateMessage = () => {
      const uChatInstance = userInstance.value[userSelected.value.id];
      if (!isEmpty(uChatInstance)) {
        uChatInstance.onCreateMessage({
          body: encodeBase64(inputText.value),
          sender: username,
          userName: id,
        });
        inputText.value = "";
      }
    };

    const onUserSelected = user => {
      userSelected.value = user;
    };

    const onMessageChanged = ({ userId, data }) => {
      set(messageData.value, userId, data);
    };

    return {
      eventId: route.params.eventId,
      userInstance,
      dropdown,
      messageData,
      userId: id,
      username,
      userList,
      userSelected,
      onUserSelected,
      onCreateMessage,
      onMessageChanged,
      inputText,
      conversationData,
      refetch,
      convertUrlToLink,
      decodeBase64,
    };
  },

  data() {
    return {
      isOpenDropdown: false,
    };
  },
  methods: {
    empty,
    get,
    toggleDropdown() {
      TweenMax.to(this.dropdown, {
        scale: this.isOpenDropdown ? 0.95 : 1,
        duration: this.isOpenDropdown ? 0.5 : 0.2,
        ease: this.isOpenDropdown ? "ease-in" : "ease-out",
        display: this.isOpenDropdown ? "none" : "block",
        opacity: this.isOpenDropdown ? 0 : 1,
      });
      this.isOpenDropdown = !this.isOpenDropdown;
    },
    doExportCSV() {
      const dataExported = {};
      forEach(this.messageData, (item, key) => {
        if (!empty(item)) {
          const conversation = find(this.conversationData, conver => conver.id === key);
          dataExported[`${conversation.name}-${conversation.id}`] = map(item, chat => ({
            createdAt: `${formatDate(chat.createdAt)} ${formatTime(chat.createdAt)}`,
            sender: chat.sender,
            content: decodeBase64(chat.body),
          }));
        }
      });
      import("@/helpers/excel").then(excel => {
        excel.exportJsonToExcelMultiSheet({
          priority: ["sender", "content", "createdAt"],
          data: dataExported,
          filename: `${this.eventId}-${this.username}`,
        });
      });
    },
    async doClearAllChat() {
      this.$emit("toggleLoading");
      await deleteUserList(this.eventId);
      this.refetch();
      this.messageData = {};
      this.$emit("toggleLoading", false);
    },
  },
};
</script>
