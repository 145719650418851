<template>
  <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5" @submit="onSubmit">
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Name: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.name"
          name="name"
          type="text"
          :class="{
            'text-danger border border-danger': errors['name'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["name"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Title page: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.title"
          name="title"
          type="text"
          :class="{
            'text-danger border border-danger': errors['title'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["title"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Date: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <div class="inline-flex w-full">
          <div class="w-20 md:w-1/2 lg:w-1/3">
            <Field
              v-model="dateTime.date"
              name="eventDate"
              type="date"
              :class="{
                'text-danger border border-danger': errors['eventDate'],
              }"
              class="
                appearance-none
                bg-off-white-1
                dark:bg-dark-bgPrimary
                text-xs
                md:text-base
                text-black-1
                dark:text-dark-textPrimary
                text-center
                md:text-left
                border border-grey-mid
                focus:ring-magenta focus:border-magenta
                rounded
                py-2
                md:py-3
                px-2
                md:px-4
                mb-3
                mr-4
                md:mr-12
              "
            />
            <p class="text-danger dark:text-danger text-base italic mr-1 md:mr-14 lg:mr-28">
              {{ errors["eventDate"] }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Welcome Message: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.welcomeMessage"
          as="textarea"
          name="welcomeMessage"
          rows="4"
          :class="{
            'text-danger border border-danger': errors['welcomeMessage'],
          }"
          class="
            w-full
            text-xs
            md:text-base
            text-black-1
            dark:text-dark-textPrimary
            bg-off-white-1
            dark:bg-dark-bgPrimary
            border border-grey-mid
            focus:ring-magenta focus:border-magenta
            rounded
            p-2
            md:p-3
          "
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["welcomeMessage"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Virtual Event URL: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <a :href="`${eventUrl}/${currentEvent.id}`" target="_blank">
          {{ `${eventUrl}/${currentEvent.id}` }}
        </a>
        <!-- <Field
          name="virtualEventUrl.url"
          v-model="currentEvent.virtualEventUrl.url"
          type="text"
          :class="{
            'text-danger border border-danger': errors['virtualEventUrl.url'],
          }"
          class="
            block
            w-full
            appearance-none
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-xs
            md:text-base
            text-black-1
            dark:text-dark-textPrimary
            border border-grey-mid
            focus:ring-magenta
            focus:border-magenta
            rounded
            py-2
            md:py-3
            px-2
            md:px-4
            mb-3
          "
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["virtualEventUrl.url"] }}
        </p> -->
      </div>
    </div>

    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Using password: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="settings.isProtect" class="mb-2 self-start" />
        <template v-if="settings.isProtect">
          <Field
            v-model="settings.password"
            type="text"
            name="password"
            rows="5"
            :class="{
              'text-danger border border-danger': errors['password'],
            }"
            class="
              w-full
              text-xs
              md:text-base
              text-black-1
              dark:text-dark-textPrimary
              bg-off-white-1
              dark:bg-dark-bgPrimary
              border border-grey-mid
              focus:ring-magenta focus:border-magenta
              rounded
              p-2
              md:p-3
            "
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["password"] }}
          </p>
        </template>
      </div>
    </div>
    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Allow anonymous login: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="settings.isAnonymousLogin" class="mb-2 self-start" />
      </div>
    </div>
    <!-- <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label
          for="fundraising-site-url"
          class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"
        >
          Fundraising Site URL:
        </label>
      </div>
      
      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <div class="grid grid-cols-1 gap-4">
          <div>
          <div>
            <Field
              name="fundraisingSiteUrl.url"
              v-model="currentEvent.fundraisingSiteUrl.url"
              type="text"
              :class="{
                'text-danger border border-danger':
                  errors['fundraisingSiteUrl.url'],
              }"
              class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors["fundraisingSiteUrl.url"] }}
            </p>
          </div> 


        </div> 

      </div>
    </div> -->

    <button
      type="submit"
      class="button_large-Alternative absolute right-0 -bottom-4 md:-top-6 lg:-top-6 xl:-top-6 md:bottom-auto uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </Form>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

import { getMomentT, formatDate } from "@Helpers/date";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from "moment";
import * as _ from "lodash";

import { sleep } from "@Helpers/sleep";
import { putEvent } from "@API/event/event-repository";
import Switch from "@/components/layouts/Switch.vue";
import { get } from "lodash";

export default {
  name: "GeneralTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    Switch,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    const currentEvent = computed(() => props.eventDetail);

    const settings = reactive({
      isProtect: !!get(currentEvent.value, "settings.isProtect"),
      password: get(currentEvent.value, "settings.password", ""),
      isAnonymousLogin: get(currentEvent.value, "settings.isAnonymousLogin", false),
    });
    const dateTime = computed(() => ({
      date: formatDate(currentEvent.value.dateTime, "yyyy-MM-DD"),
    }));

    const schema = Yup.object().shape({
      name: Yup.string().required("Event name is required"),
      eventDate: Yup.string().required("Event date is required"),
      password: Yup.lazy(() => (settings.isProtect ? Yup.string().required("password is required") : Yup.string())),
      // virtualEventUrl: Yup.object().shape({
      //   url: Yup.string()
      //     .matches(
      //       /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //       "Enter correct url",
      //     )
      //     .required("Virtual Event Url is required"),
      // }),
      /*      fundraisingSiteUrl: Yup.object().shape({
        // name: Yup.string().required("Fundraising Site Name is required"),
        url: Yup.string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter correct url"
          )
          .required("Fundraising Site Url is required"),
      }),
*/
    });
    const eventUrl = process.env.VUE_APP_URL;

    return {
      currentEvent,
      dateTime,
      schema,
      settings,
      eventUrl,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          dateTime: getMomentT(this.dateTime.date).format(),
          settings: {
            ...this.currentEvent.settings,
            ...this.settings,
          },
        };
        _.forOwn(payload, (value, key) => {
          if (key.includes("Url") && (!value.url.length || value.url === " ")) delete payload[key];
        });
        const result = await putEvent(payload);
        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Successfully!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
